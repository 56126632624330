<template>
  <div class="">
    <footer v-if="false">
      <div class="footer-disclaimer">
        <img src="~@/assets/excelerate-logo.png" class="logo" />
      </div>
      <div class="footer-navigation">
        <router-link to="/user/terms/">Terms and conditions</router-link>
        <span>©Copyright 2020 Excelerate.careers</span>
      </div>
    </footer>
    <div
      v-if="professional"
      class="d-flex justify-content-center flex-wrap footer-image-list"
    >
      <div v-for="(image, index) in signupImages" :key="index">
        <img :src="getImgUrl(image)" alt="" srcset="" class="image-list" />
      </div>
    </div>
    <student-footer></student-footer>
  </div>
</template>

<script>
// import {mapState} from 'vuex'
import StudentFooter from "../components/StudentFooter.vue";
export default {
  name: "MasterFooter",
  props: {
    // title: String,
  },
  components: {
    StudentFooter,
  },
  data() {
    return {
      signupImages: null,
    };
  },
  watch: {
    signupData: function (val) {
      this.signupImages = JSON.parse(this.signupData.image_list);
    },
  },
  computed: {
    professional() {
      return this.$route.path.includes("professional-signup") ? true : false;
    },
    student() {
      return this.$route.path.includes("student-signup") ? true : false;
    },
    signupData: function () {
      var vm = this;
      return vm.$store.getters.signupData;
    },
    
  },
  methods: {
    getImgUrl(path) {
      return `${process.env.VUE_APP_BASE_URL}${path}`;
    },
  },
};
</script>
<style scoped>
.footer-image-list {
  position: absolute;
  bottom: 5px;
  column-gap: 30px;
  row-gap: 20px;
  opacity: 0.4;
}
.image-list {
  width: 100px;
  /* height: 100px; */
}
.fixed-footer{
  margin-bottom: -90px;
}
@media (max-width: 767px){
  .image-list{
    width: 50px;
  }
  .footer-image-list{
    flex-direction: row;
    position:relative;
  }
  .fixed-footer{
    display: none;
  }
}
</style>